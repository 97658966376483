import { Brand } from './typings'

const bp: Brand = {
  clientInfo: {
    displayName: 'BP',
    accountNumberLengths: [16],
    footerDisplay: 'mybpstation.com',
    footerLink: 'https://www.mybpstation.com/',
    applicationUrl: '',
    dsecApigeeClientId: {
      development: 'XTofJl95Edyw9b3Q918VbSjePv8V5lJf',
      production: 'yK1yfdEIkSdq0A4eL8PcTL2AXAu7s2cK'
    },
    persistApigeeClientId: { development: '', production: '' },
    setpayApigeeClientId: {
      development: '',
      production: ''
    },
    metaTagCardTitle: {
      en: 'Credit Card',
      es: 'Tarjeta de Crédito'
    },
    metaTagCardDescription: {
      en: `Log into your BP credit card account online to pay your bills, check your FICO score, sign up for paperless billing, and manage your account preferences`,
      es: `Ingrese a su cuenta de la Tarjeta de Crédito BP en línea para pagar sus facturas, revisar su puntuación FICO, inscribirse en facturas digitales y manejar su cuenta.`
    },
    languages: ['en'],
    vanityUrl: 'mybpstation.com',
    hostname: 'bp.syf.com',
    linkingRedirect: '',
    ivaKey: '',
    hasMss: true,
    hasCpl: false,
    promoUrl: 'http://www.bpmerewardsvisa.com/',
    hasCplOldErrorCode: false,
    isMultiAccount: false,
    footerContent: {
      en: 'Your BP Visa® Credit Card or BP Credit Card is issued by Synchrony Bank. The Synchrony Bank Privacy Policy governs the use of the BP Visa® Credit Card or BP Credit Card. The use of this site is governed by the use of the Synchrony Bank Internet Privacy Policy, which is different from the privacy policy of BP. The BP Visa® Credit Card is issued by Synchrony Bank pursuant to a license from Visa USA Inc.',
      es: ''
    },
    phoneNumbers: {
      loan: '',
      plcc: '1-844-832-0035',
      dualcard: '1-844-832-0030',
      fraud: '1-844-832-0030',
      customerService: '1-844-832-0030'
    },
    iOSAppId: '',
    androidApp: {
      id: '',
      author: '',
      title: '',
      image: ''
    },
    crossBorder: false,
    cplHeroBannerDisplayName: '',
    termsOfService: {
      en: '/essimages/cs/groups/ess_webasset/dualcard/@bp/en/html/ess010582.html',
      es: ''
    },
    onlinePrivacy: {
      en: '/essimages/cs/groups/ess_webasset/generic/@bp/en/html/ess010619.html',
      es: ''
    },
    privacyPolicy: {
      en: '/essimages/cs/groups/ess_webasset/generic/@bp/en/html/ess020345.html',
      es: ''
    },
    websiteUsage: {
      en: '/essimages/cs/groups/ess_webasset/generic/@bp/en/html/ess010621.html',
      es: ''
    },
    voice: {
      cardName: '',
      google: {
        termsAndConditionsLink: ''
      },
      alexa: {
        termsAndConditionsLink: ''
      }
    }
  },
  palette: {
    button: {
      primary: {
        text: '#FFFFFF',
        hover: '#025E29'
      },
      secondary: {
        hover: '#F1F1F1'
      },
      background: '#017733'
    },
    field: {
      icon: '#000000',
      focus: '#34657F',
      label: ''
    },
    progressStepBar: {
      fill: '#017733',
      label: '#01773'
    },
    progressBar: {
      complete: '',
      error: '',
      inProgress: '',
      background: ''
    },
    checkbox: {
      background: '#3A980E',
      font: '#FFFFFF'
    },
    switch: { background: '' },
    heroBackground: '',
    bodyBackground: '#FFFFFF',
    iconBackground: '#000000',
    headerBackground: '#FFFFFF',
    modalBackground: '#FFFFFF',
    formBackground: '#f2f2f2',
    headerDivider: '#017733',
    footerDivider: '#999999',
    contrastingText: '#ffffff',
    bodyText: '#333333',
    languageSelector: '#0077CA',
    focus: '',
    link: '#017733',
    footer: {
      background: '#D6D6D6',
      text: '#5A5A5A',
      link: '#5A5A5A'
    }
  },
  images: {
    logo: {
      en: '/assets/bp/logo.png',
      es: '/assets/bp/logo.png'
    },
    hero: '/assets/bp/backgorund.jpeg',
    heroBgDesktop: '',
    heroBgMobile: '',
    mainPromo: {
      en: '/assets/bp/promo.png',
      es: '/assets/bp/promo.png'
    },
    voiceCplLogo: ''
  }
}

export default bp
